import React, { FC, useCallback, useMemo, useState } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { InviteUserType, UserTypes } from 'constants/user';
import useModal from 'hooks/use-modal/useModal';
import { getCompanyById } from 'modules/companies/action';
import {
  selectCanStartDeal,
  selectCompanyDealParametersHasActiveDeal,
  selectCurrentCompanyId,
} from 'modules/companies/selectors';
import { getCompanyIacDeal } from 'modules/company-iac/action';
import { selectIsActionLoading } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CompanyIacStartDealModal } from 'page-components/company-iac';
import Typography from 'shared-components/Typography';
import { checkIsAdmin, getArchivedCompanyIacLink, getArchivedDueDiligenceLink, navigateWithFilter } from 'utils';

import { CompanyProfileInvite, MyInvitesModalForm } from '../../index';
import AdminCompanyProfileRelatedItem from './AdminCompanyProfileRelatedItem';
import AdminCompanyProfileRelatedSectionPreloader from './AdminCompanyProfileRelatedSectionPreloader';

interface Props {
  isLoading: boolean;
}

const RELATED_ITEM_SHARED_BUTTON_CLASSNAME = 'w-full !2xl:w-[160px] w-full';

const AdminCompanyProfileRelatedSection: FC<Props> = ({ isLoading }) => {
  const dispatch = useAppDispatch();
  const { id: companyId } = useParams();
  const id = useAppSelector(selectCurrentCompanyId);
  const canStartDeal = useAppSelector(selectCanStartDeal);
  const isInvitesActionLoading = useAppSelector(selectIsActionLoading);
  const hasCompanyActiveDeal = useAppSelector(selectCompanyDealParametersHasActiveDeal);

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const { onOpen: onOpenStartDealModal, ...startDealModalProps } = useModal();

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const loadCompany = useCallback(() => {
    if (!companyId) {
      return;
    }

    dispatch(getCompanyById({ id: companyId, isAdmin: true }));
  }, [companyId, dispatch]);

  const onStartDeal = useCallback(() => {
    if (!isAdmin || !companyId) {
      return;
    }

    setIsButtonLoading(true);

    dispatch(getCompanyIacDeal(companyId))
      .unwrap()
      .then(() => onOpenStartDealModal())
      .finally(() => setIsButtonLoading(false));
  }, [isAdmin, companyId, dispatch, onOpenStartDealModal]);

  const companyProfileRelatedItems = useMemo(() => {
    if (!id) return [];

    return [
      {
        buttonVariant: ButtonVariants.LINK,
        title: 'Share Exchange Requests',
        description: 'View all Share Exchange Requests',
        linkPath: navigateWithFilter(true, ROUTES.shareExchangeRequests, 'company_id', id.toString()),
      },
      {
        buttonVariant: ButtonVariants.LINK,
        title: 'Associated Founders/Directors',
        description: 'View all Associated Founders',
        linkPath: navigateWithFilter(true, ROUTES.founders, 'company', id.toString()),
      },
      {
        title: 'Invite Investors',
        node: (
          <MyInvitesModalForm
            companyId={id}
            buttonClassName={cn(RELATED_ITEM_SHARED_BUTTON_CLASSNAME, 'mt-0')}
            loading={isInvitesActionLoading}
            type={UserTypes.INVESTOR}
            inviteType={InviteUserType.INVESTOR_TO_COMPANY}
          />
        ),
        children: (
          <div className={cn('flex flex-col', { 'mt-4': !hasCompanyActiveDeal })}>
            {hasCompanyActiveDeal ? (
              <Typography
                className={cn(
                  RELATED_ITEM_SHARED_BUTTON_CLASSNAME,
                  'w-full flex items-center justify-center self-end h-fit my-1',
                )}
              >
                Or
              </Typography>
            ) : null}
            <CompanyProfileInvite
              className='my-0 flex-col !2xl:flex-row gap-4 !2xl:gap-0'
              buttonClassName={RELATED_ITEM_SHARED_BUTTON_CLASSNAME}
              companyId={id}
              hasActiveDeal
            />
          </div>
        ),
      },
      ...(hasCompanyActiveDeal
        ? [
            {
              title: 'Invite Founders',
              node: (
                <MyInvitesModalForm
                  companyId={id}
                  buttonClassName={cn(RELATED_ITEM_SHARED_BUTTON_CLASSNAME, 'mt-0')}
                  loading={isInvitesActionLoading}
                  type={UserTypes.FOUNDER}
                />
              ),
            },
          ]
        : []),
      {
        buttonVariant: ButtonVariants.LINK,
        title: 'Associated Investors',
        description: 'View all Associated Founders',
        linkPath: navigateWithFilter(true, ROUTES.investors, 'company', id.toString()),
      },
      {
        buttonVariant: ButtonVariants.LINK,
        title: 'Archived Due Diligence',
        description: 'View all Archived Due Diligence',
        buttonText: 'View',
        linkPath: getArchivedDueDiligenceLink(id, isAdmin),
      },

      {
        buttonVariant: ButtonVariants.LINK,
        title: 'Archived IAC',
        description: 'View all Archived IAC',
        buttonText: 'View',
        linkPath: getArchivedCompanyIacLink(id, isAdmin),
      },

      {
        buttonVariant: ButtonVariants.SECONDARY,
        disabled: !canStartDeal,
        title: 'Deal Information',
        buttonText: 'Start New Deal',
        isLoading: isButtonLoading,
        action: onStartDeal,
      },
    ];
  }, [canStartDeal, hasCompanyActiveDeal, id, isAdmin, isButtonLoading, isInvitesActionLoading, onStartDeal]);

  return (
    <>
      <CompanyIacStartDealModal companyId={companyId} onSuccessCallback={loadCompany} {...startDealModalProps} />

      <div className='w-full md:w-[35%] flex flex-col'>
        {isLoading ? (
          <AdminCompanyProfileRelatedSectionPreloader />
        ) : (
          <>
            {companyProfileRelatedItems.map((item, index) => (
              <AdminCompanyProfileRelatedItem key={`${item.title}-${index}`} {...item} />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default AdminCompanyProfileRelatedSection;
