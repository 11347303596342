import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { getMyCompaniesWithInvestmentsTableColumns, INVESTOR_COMPANIES_INVESTMENT } from 'constants/investments';
import { getInvestorCompaniesInvestments } from 'modules/investors/action';
import { selectInvestorCompaniesInvestments } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import Table, { TableSkeleton } from 'shared-components/table';

import NoFinalisedInvestmentsFound from '../investments/my-investments/my-companies-with-finalised-investments/NoFinalisedInvestmentsFound';
import MyCompaniesWithInvestmentsTableItem from '../investments/my-investments/MyCompaniesWithInvestmentsTableItem';
import NoCompaniesInvestments from './NoCompaniesInvestments';

const InvestorCompaniesInvestments = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { isLoading, list } = useAppSelector(selectInvestorCompaniesInvestments);
  const hasInvestments = !!list?.flatMap(({ investments }) => investments)?.length;

  useEffect(() => {
    if (id) {
      dispatch(getInvestorCompaniesInvestments({ id, page: 1, per_page: 1000 }));
    }
  }, [dispatch, id]);

  return (
    <>
      <Table columns={INVESTOR_COMPANIES_INVESTMENT}>
        {hasInvestments && !isLoading ? (
          <>
            {list.map((companyWithInvestments) => {
              const { seId } = companyWithInvestments;
              return companyWithInvestments.investments.map((investment, index) => (
                <MyCompaniesWithInvestmentsTableItem
                  key={investment.id}
                  investment={investment}
                  isFirstElement={index === 0}
                  companyId={companyWithInvestments.id}
                  companyName={investment?.companyName}
                  companyNumber={companyWithInvestments.companyNumber}
                  companyActiveShareExchange={companyWithInvestments.activeShareExchangeRequest}
                  disabledActions={!!companyWithInvestments.activeShareExchangeRequest}
                  declineReason={companyWithInvestments?.declineReason}
                  isActiveExchange={companyWithInvestments?.isActiveExchange}
                  shareExchangeRequestId={companyWithInvestments?.shareExchangeRequestId}
                  serDeclined={companyWithInvestments?.serDeclined}
                  userCompanySEStatus={companyWithInvestments?.userCompanySEStatus}
                  draftDealId={seId}
                />
              ));
            })}
          </>
        ) : isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={getMyCompaniesWithInvestmentsTableColumns().length} />
        ) : (
          <NoCompaniesInvestments />
        )}
      </Table>
    </>
  );
};

export default InvestorCompaniesInvestments;
