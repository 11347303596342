import React, { KeyboardEventHandler, useState } from 'react';

import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { ReactComponent as PencilSvg } from 'assets/svg/pencil.svg';
import { ButtonVariants } from 'constants/shared/button';
import { getCompanyAddressFields, SignUpFieldNames } from 'constants/sign-up';
import useCompanySelect from 'hooks/useCompanySelect';
import { selectCompanyData } from 'modules/companies/selectors';
import { useAppSelector } from 'modules/store';
import { Button, CompanySelectField, CompanySelectValueContainerWithTitle, FormContent } from 'shared-components';

import { COMPANY_NUMBER_FIELD_ALLOWED_KEYS, COMPANY_NUMBER_FIELD_CTRL_COMBINATIONS } from '../../constants/entities';
import { LETTERS_PATTERN } from '../../constants/global';

type Props<T extends FieldValues> = {
  control: Control<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  reset: UseFormReset<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  isStaticCompany?: boolean;
};

const EntityCompanyFields = <T extends FieldValues>({
  control,
  register,
  errors,
  reset,
  watch,
  setValue,
  isStaticCompany,
}: Props<T>) => {
  const companyData = useAppSelector(selectCompanyData);

  const [isFormAvailable, setIsFormAvailable] = useState(isStaticCompany);

  const setAsAvailable = () => setIsFormAvailable(true);

  const [handleChangeCompanyName, handleChangeCompanyNumber, isCompanyNameCreated, isCompanyNumberCreated] =
    useCompanySelect<T>(watch, setValue, reset, true, companyData ?? null, setAsAvailable);

  const handleNumberOnlySelect: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const keyPressed = event.nativeEvent.key;

    const isCtrlCommand = event.ctrlKey || event.metaKey;

    if (
      !COMPANY_NUMBER_FIELD_ALLOWED_KEYS.includes(keyPressed) &&
      LETTERS_PATTERN.test(keyPressed) &&
      !(isCtrlCommand && COMPANY_NUMBER_FIELD_CTRL_COMBINATIONS.includes(keyPressed))
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className='flex flex-col gap-7 col-span-4'>
        <div className='flex flex-col xs:flex-row gap-7'>
          <CompanySelectField
            disabled={isStaticCompany}
            className='flex-1'
            name={SignUpFieldNames.COMPANY_NUMBER}
            placeholder='Company Number'
            label='Company No.'
            control={control}
            onChange={handleChangeCompanyNumber}
            disableRequest={isCompanyNameCreated}
            description='Minimum of 5 characters required for search'
            components={{ ValueContainer: CompanySelectValueContainerWithTitle }}
            creatable
            isReversed
            isClearable
            onKeyDown={handleNumberOnlySelect}
          />
          <CompanySelectField
            disabled={isStaticCompany}
            className='flex-1'
            label='Legal Company Name'
            name={SignUpFieldNames.COMPANY_NAME}
            control={control}
            onChange={handleChangeCompanyName}
            disableRequest={isCompanyNumberCreated}
            creatable
            isClearable
          />
        </div>

        {!isStaticCompany && (
          <Button type='button' variant={ButtonVariants.SECONDARY} className='w-fit mb-6' onClick={setAsAvailable}>
            Edit <PencilSvg className='ml-2' />
          </Button>
        )}
      </div>
      <FormContent
        fields={getCompanyAddressFields()}
        isDisabled={!isFormAvailable}
        register={register}
        control={control}
        errors={errors}
      />
    </>
  );
};

export default EntityCompanyFields;
