import { createSelector } from '@reduxjs/toolkit';

import { getCompaniesInvestmentsWithFormattedNumbers } from 'utils/investments';

import { RootState } from '../store';

const selectState = (x: RootState) => x.investorsReducer;

export const selectInvestorsData = createSelector(selectState, ({ investorsList }) => investorsList);
export const selectInvestor = createSelector(selectState, ({ investor }) => investor);
export const selectInvestorShareExchanges = createSelector(
  selectState,
  ({ investorShareExchanges }) => investorShareExchanges,
);

export const selectInvestorInvoices = createSelector(selectState, ({ investorInvoices }) => investorInvoices);

export const selectInvestorCompaniesInvestments = createSelector(selectState, (state) => {
  return {
    ...state.investorCompaniesInvestments,
    list: getCompaniesInvestmentsWithFormattedNumbers(state.investorCompaniesInvestments.list ?? []),
  };
});

export const selectInvestorActivityLogs = createSelector(selectState, ({ activityLogs }) => activityLogs);
export const selectInvestorActivityLog = createSelector(selectState, ({ activityLog }) => activityLog);

export const selectInvestorsMetrics = createSelector(selectState, ({ investorsMetrics }) => investorsMetrics);

export const selectInvestorsPieChartMetrics = createSelector(
  selectState,
  ({ investorsPieMetrics }) => investorsPieMetrics,
);
