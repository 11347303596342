import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.authReducer;

export const selectCompanies = createSelector(selectState, (state) => state.companies?.items);

export const selectInvestorsList = createSelector(selectState, (state) => state.investorsList);

export const selectFoundersList = createSelector(selectState, (state) => state.foundersList);

export const selectIsActionLoading = createSelector(selectState, (state) => state.isActionLoading);
export const selectIsEmailChanged = createSelector(selectState, (state) => state.isEmailChanged);

export const selectIsInvitationLoading = createSelector(selectState, (state) => state.isInvitationLoading);

export const selectInvitedUserSignUp = createSelector(selectState, (state) => state.invitedUser);

export const selectSignUpData = createSelector(selectState, (state) => state.signUpData);

export const selectInvitedCompanyId = createSelector(selectState, (state) => state.invitedUser?.companyId);

export const selectResetPasswordUserType = createSelector(selectState, (state) => state.resetPasswordUserType);
