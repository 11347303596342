import { MetaState } from './global.interfaces';

export interface Todo {
  id: number;
  action: TodoTypes;
  updatedAt: string;
}

export enum TodoTypes {
  SHARE_EXCHANGE_REQUEST = 'reviewSER',
  DUE_DILIGENCE = 'reviewDD',
  IAC = 'reviewIAC',
}

export type TodoActionsParamsType = Pick<Todo, 'id'>;

export type TodosState = {
  todosList: TodosList;
};

export interface TodosList extends MetaState {
  list: Todo[] | null;
  isLoading: boolean;
}

export interface TodosResponse {
  data: Todo[];
}
