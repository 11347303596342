import React, { useEffect, useRef, useState } from 'react';

import SignatureCanvas from 'react-signature-canvas';

import { selectUser } from 'modules/current-user/selectors';
import { getSignatureFromUrl } from 'modules/signatures/action';
import { selectSignatureUrl } from 'modules/signatures/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import SignatureActions from './SignatureActions';
import { SignatureSkeleton } from './SignatureSkeleton';
import SignatureUpload from './SignatureUpload';

const SignaturePad = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const { isLoading } = useAppSelector(selectSignatureUrl);

  const containerRef = useRef<HTMLDivElement>(null);
  const sigCanvas = useRef<SignatureCanvas>(null);

  const [canvasWidth, setCanvasWidth] = useState<number>(0);

  useEffect(() => {
    const updateCanvasWidth = () => {
      if (containerRef?.current) {
        setCanvasWidth(containerRef.current.offsetWidth);
      }
    };

    updateCanvasWidth();
    window.addEventListener('resize', updateCanvasWidth);
    return () => {
      window.removeEventListener('resize', updateCanvasWidth);
    };
  }, []);

  useEffect(() => {
    if (user && sigCanvas) {
      const userSignatureLink = user?.signature?.imageLink;

      if (userSignatureLink && sigCanvas.current) {
        const imageUrl = `${window?._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL}${userSignatureLink}`;

        dispatch(getSignatureFromUrl(imageUrl))
          .unwrap()
          .then((imageBase64) => sigCanvas?.current?.fromDataURL(imageBase64));
      }
    }
  }, [dispatch, user, sigCanvas]);

  return (
    <div className='flex flex-col gap-4' ref={containerRef}>
      {isLoading ? (
        <SignatureSkeleton />
      ) : (
        <SignatureCanvas
          ref={sigCanvas}
          penColor='black'
          canvasProps={{ height: 200, width: canvasWidth, className: 'rounded-md border border-grey-300 self-center' }}
        />
      )}
      <div className='flex justify-between'>
        <SignatureUpload sigCanvas={sigCanvas} />
        <SignatureActions sigCanvas={sigCanvas} />
      </div>
    </div>
  );
};

export default SignaturePad;
