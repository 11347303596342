import { CSSObjectWithLabel } from 'react-select';

import { FormItem, InvestmentsFieldNames, TableColumns, Option } from 'interfaces';
import { Input, DatePickerInput, SelectFormInput } from 'shared-components';

import NumberInput from '../shared-components/NumberInput';
import { NUMBER_AND_LETTERS_PATTERN, REQUIRED_MESSAGE } from './global';
import { FieldTypes } from './shared';

export const INVESTMENTS_PAGE_TITLE = 'Add your investments to planD';

export const INVESTMENTS_PAGE_DESCRIPTION =
  'Please input any existing investments that you wish to keep track of. If you prefer to upload all your investments at once, you can';

export const INVESTMENTS_PAGE_DESCRIPTION_SECOND_PART = 'and use the Batch Upload button below.';

export const INVESTMENTS_PAGE_DOWNLOAD_TEXT = 'download the template';

export const INVESTMENTS_PAGE_PREFERRED_WAY_TEXT =
  'Please input one line per invested share class. If you have invested in the same share class across multiple rounds please use blended pricing.';

export const INVESTMENTS_PAGE_DOWNLOAD_TEMPLATE_TEXT =
  'Download our Template and upload all your investments at once. When - upload it with Batch Upload button below.';

export const INVESTMENTS_PAGE_LINK_TEXT = 'here';

export const INVESTMENTS_PAGE_DOWNLOAD_TEMPLATE_LINK_TEXT = 'Download template';

export const UPLOAD_INVESTMENTS_MODAL_TITLE = 'Upload Investments';

export const UPLOAD_INVESTMENTS_MODAL_DESCRIPTION =
  'Please ensure the template structure has not been modified to ensure a successful upload If you are having errors, you can download a new template';

export const INVESTMENT_BATCH_UPLOAD_TOOLTIP =
  'Use the template above for adding all your investments to the platform.';

export const UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPE = 'text/csv';

export const UPLOAD_INVESTMENTS_MAX_FILE_SIZE = 15000000;

export const UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPES_TEXT = 'Only CSV is accepted';

export const MY_INVESTMENTS_PAGE_TITLE = 'My Investments';

export const INVESTMENTS_DOWNLOAD_TEMPLATE_PATH = 'csv/investments-template.csv';

export const REVIEW_INVESTMENTS_MODAL_TITLE = 'Review Investments';

export const DELETE_REVIEW_INVESTMENT_MODAL_TITLE = 'Are you sure you want to remove this investment?';

export const INVESTMENTS_DAXIA_PRICE_PER_SHARE_TOOLTIP_CONTENT = 'Blended price across all exchanges';

export const ADD_INVESTMENT_CLASS_OF_SHARES: FormItem = {
  name: InvestmentsFieldNames.CLASS_OF_SHARES,
  label: 'Class of Shares',
  placeholder: 'Class of Shares',
  type: FieldTypes.TEXT,
  component: Input,
  required: true,
  validation: {
    required: REQUIRED_MESSAGE,
    pattern: {
      value: NUMBER_AND_LETTERS_PATTERN,
      message: 'Valid letters and number',
    },
    maxLength: {
      value: 20,
      message: 'Max 20 chars',
    },
  },
  isAbsoluteError: true,
  className: 'mb-[0]',
};

export const GET_ADD_INVESTMENT_CLASS_OF_SHARES_FIELD = (isSelect: boolean, options?: Option[]): FormItem => ({
  name: InvestmentsFieldNames.CLASS_OF_SHARES,
  label: 'Class of Shares',
  placeholder: 'Class of Shares',
  type: isSelect ? FieldTypes.SELECT : FieldTypes.TEXT,
  component: isSelect ? SelectFormInput : Input,
  required: true,
  ...(isSelect && {
    customCompanySelectStyles: {
      input: (style: CSSObjectWithLabel) => ({ ...style, height: '28px' }),
    },
  }),
  validation: {
    required: REQUIRED_MESSAGE,
    pattern: {
      value: NUMBER_AND_LETTERS_PATTERN,
      message: 'Valid letters and number',
    },
    maxLength: {
      value: 20,
      message: 'Max 20 chars',
    },
  },
  tooltipContent: 'As stated on the Subscription Documents/Share Certificate.',
  options: options,
  isAbsoluteError: true,
  className: 'mb-0',
});

export const ADD_INVESTMENT_FIELDS: FormItem[] = [
  {
    name: InvestmentsFieldNames.TOTAL_SHARES,
    label: 'No. Shares',
    placeholder: 'No. Shares',
    type: FieldTypes.NUMBER,
    required: true,
    component: NumberInput,
    validation: {
      required: REQUIRED_MESSAGE,
    },
    isAbsoluteError: true,
    className: 'mb-0',
  },
  {
    name: InvestmentsFieldNames.PRICE_PER_SHARE,
    label: 'Cost price per share(£)',
    placeholder: 'Cost price per share',
    type: FieldTypes.NUMBER,
    required: true,
    component: NumberInput,
    className: 'min-w-[160px] mb-0',
    validation: {
      required: REQUIRED_MESSAGE,
    },
    disabled: true,
    isAbsoluteError: true,
    fractionLength: 50,
    tooltipContent: 'Original price paid per share',
  },
  {
    name: InvestmentsFieldNames.INVESTMENT_DATE,
    label: 'Investment Date',
    type: FieldTypes.MONTH,
    component: DatePickerInput,
    max: new Date(),
    isAbsoluteError: true,
    className: 'mb-0',
  },
  {
    name: InvestmentsFieldNames.ORIGINAL_INVESTMENT_PRICE,
    label: 'Initial Investment Value',
    placeholder: 'Initial Investment Value',
    type: FieldTypes.NUMBER,
    required: true,
    component: NumberInput,
    validation: {
      required: REQUIRED_MESSAGE,
    },
    isAbsoluteError: true,
    className: 'mb-0',
    fractionLength: 50,
  },
];

export const INVESTMENTS_TABLE_FILTER_FORM_FIELDS: FormItem[] = [
  {
    name: InvestmentsFieldNames.INVESTMENT_DATE,
    label: 'Investment Date',
    type: FieldTypes.MONTH,
    component: DatePickerInput,
    max: new Date(),
    isAbsoluteError: true,
    className: 'mb-0 xs:mr-5 xs:max-w-[200px]',
  },
];

export const INVESTMENTS_TABLE_COLUMNS: TableColumns[] = [
  { id: InvestmentsFieldNames.COMPANY_NUMBER, title: 'Company Name' },
  { id: InvestmentsFieldNames.CLASS_OF_SHARES, title: 'Class of Shares' },
  { id: InvestmentsFieldNames.TOTAL_SHARES, title: 'No. Shares' },
  { id: InvestmentsFieldNames.PRICE_PER_SHARE, title: 'Share Price(£)' },
  { id: InvestmentsFieldNames.INVESTMENT_DATE, title: 'Investment Date' },
  { id: InvestmentsFieldNames.ORIGINAL_INVESTMENT_PRICE, title: 'Original Investment Value' },
  { id: 'actions', title: '', width: 'w-28' },
];

export enum MY_INVESTMENTS_PAGE_TABS_IDS {
  OVERVIEW = 'overview',
  INVESTMENTS = 'investments',
  FUND = 'FUND',
}

export const MY_INVESTMENTS_MAIN_TABLE_COLUMNS = [
  { id: 'actions', title: 'Actions' },
  { id: 'companyName', title: 'Company Name', sortable: true },
];

export const MY_INVESTMENTS_ADDITIONAL_TABLE_COLUMNS: TableColumns[] = [
  { id: 'companyNumber', title: 'Company Number', sortable: true },
  { id: 'classOfShare', title: 'Class of Shares' },
  { id: 'pricePerShare', title: 'Price Per Share(£)' },
];

export const getMyCompaniesWithInvestmentsTableColumns = (
  hideActionsColumn?: boolean,
  hideCurrentInvestmentColumn?: boolean,
): TableColumns[] => [
  ...MY_INVESTMENTS_MAIN_TABLE_COLUMNS,
  { id: 'companyNumber', title: 'Company Number', sortable: true },
  { id: 'classOfShare', title: 'Class of Shares' },
  { id: 'pricePerShare', title: 'Cost Price per Share (£)' },
  ...(!hideCurrentInvestmentColumn ? [{ id: 'currentInvestmentValue', title: 'Current Investment Value' }] : []),
  { id: 'investmentDate', title: 'Investment Date' },
  { id: 'noOfShares', title: 'No. Shares' },
  { id: 'originalInvestmentPrice', title: 'Original Investment Value' },
  { id: 'status', title: 'Status' },
  ...(!hideActionsColumn ? [{ id: 'actions', title: '' }] : []),
];

export const INVESTOR_COMPANIES_INVESTMENT = [
  { id: 'companyName', title: 'Company Name', sortable: true },
  { id: 'companyNumber', title: 'Company Number', sortable: true },
  { id: 'classOfShare', title: 'Class of Shares' },
  { id: 'pricePerShare', title: 'Cost Price per Share (£)' },
  { id: 'currentInvestmentValue', title: 'Current Investment Value' },
  { id: 'investmentDate', title: 'Investment Date' },
  { id: 'noOfShares', title: 'No. Shares' },
  { id: 'originalInvestmentPrice', title: 'Original Investment Value' },
  { id: 'status', title: 'Status' },
];

export const OTHER_INVESTMENTS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'companyName', title: 'Company Name', sortable: true },
  { id: 'investmentDate', title: 'Investment Date', sortable: true },
];

export const MY_INVESTMENTS_OVERVIEW_TABLE_COLUMNS = [
  { id: 'companyName', title: 'Company Name', sortable: true },
  ...MY_INVESTMENTS_ADDITIONAL_TABLE_COLUMNS,
  { id: 'investmentValue', title: 'Investment Value' },
  { id: 'investmentDate', title: 'Investment Date', sortable: true },
  { id: 'actions', title: '' },
];

export const OTHER_INVESTMENTS_AVAILABLE_KEYS = [
  InvestmentsFieldNames.AMOUNT_INVESTED,
  InvestmentsFieldNames.INVESTMENT_DATE,
];

export const REVIEW_INVESTMENTS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'companyName', title: 'Company Name' },
  { id: 'classOfShares', title: 'Class of Shares' },
  { id: 'noShares', title: 'No. Shares' },
  { id: 'sharePrice', title: 'Share Price' },
  { id: 'totalInvested', title: 'Total Invested' },
  { id: 'investmentDate', title: 'Investment Date' },
  { id: 'originalInvestmentPrice', title: 'Original Investment Value' },
  { id: 'actions', title: '' },
];

export const SUCCESS_ADD_INVESTMENT_MODAL_PROMPT_TITLE = 'Would you like to proceed with creating a share exchange?';

export const DAXIA_PATTERN = 'Daxia Fund';
