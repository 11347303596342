import { COMPANY_IAC_INVITING_FUND_ADD_NEW_TITLE, COMPANY_IAC_INVITING_FUND_MODAL_TITLE } from 'constants/company-iac';
import {
  DEAL_PARAMETERS_EMAIL_PREVIEW_MODAL_DESCRIPTION,
  DEAL_PARAMETERS_EMAIL_PREVIEW_MODAL_TITLE,
} from 'constants/deal-parameters';
import { CompanyInvestor } from 'interfaces';

export const getDeleteInvestmentModalTitle = (companyName: string, amountInvested: string) =>
  `Are you sure you want to remove investment of ${companyName} (£${amountInvested})?`;

export const getInvitingModalTitle = (condition: boolean) =>
  condition ? COMPANY_IAC_INVITING_FUND_ADD_NEW_TITLE : COMPANY_IAC_INVITING_FUND_MODAL_TITLE;

export const getStopShareExchangeModalTitle = (companyName: string) =>
  `Are you sure you want to stop the share exchange process with ${companyName}?`;

export const getStartInvestmentShareExchangeDescription = (classOfShares: string[]) =>
  `Class(es) of shares that are supported for this share exchange: ${classOfShares?.join(', ')}`;

export const getCompanyInvestorsInviteShareExchangeModalDescription = ({
  companyName,
  investors,
}: {
  companyName?: string;
  investors: CompanyInvestor[];
}) => {
  if (!companyName || !investors?.length) return '';

  const fullNames =
    investors?.length > 1
      ? investors?.reduce((prev, { fullName }, index) => `${prev} ${index ? ',' : ''} ${fullName}`, '')
      : investors[0]?.fullName;

  const stringPrefix = investors?.length > 1 ? 'Investors' : 'Investor';

  return `${stringPrefix} ${fullNames} has already share exchange for the ${companyName}.`;
};

export const getShareExchangeSnippetNotifyTitle = (condition: boolean) =>
  condition
    ? 'There is no active deal(s) for your Company(ies).'
    : 'Please add your Company to initiate or view Deal progress';

export const getDealEmailPreviewModalTitle = (condition: boolean) =>
  condition ? 'Please type email' : DEAL_PARAMETERS_EMAIL_PREVIEW_MODAL_TITLE;

export const getDealEmailPreviewModalDescription = (condition: boolean, isAdditional?: boolean) => {
  if (isAdditional)
    return 'If there are no associated emails then please input any email addresses that the summary should be sent to e.g. Fund Manager, Accountants, Directors, etc';

  return condition
    ? 'Currently, no founder is associated with the deal'
    : DEAL_PARAMETERS_EMAIL_PREVIEW_MODAL_DESCRIPTION;
};

export const getDeleteDealsModalTitle = (count: number) =>
  `Are you sure you want to delete ${count > 1 ? 'these' : 'this'} Share Exchange${count > 1 ? 's' : ''}?`;

export const getTitleWithoutMyWord = (title: string, condition?: boolean) =>
  condition && title?.startsWith('My ') ? title?.replace('My ', '') : title;

export const getTitleWithReplacedYourWord = (title: string, replacement: string, condition?: boolean) =>
  condition ? title?.replace(/your/gi, replacement) : title;

export const getSigningCompanyIacModalTitle = (companyName: string, isSigning?: boolean) => {
  return isSigning
    ? `Are you sure that you want to sign IAC for ${companyName}?`
    : `Do you want to cancel signature of IAC?`;
};
