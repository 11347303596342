import React, { useEffect } from 'react';

import { API_URL } from '../../configs/http-client';
import { getSignatureFromUrl } from '../../modules/signatures/action';
import { selectSignatureUrl } from '../../modules/signatures/selectors';
import { useAppDispatch, useAppSelector } from '../../modules/store';
import { SignatureSkeleton } from '../signature-settings/SignatureSkeleton';

interface Props {
  urlToImage: string;
}

const CompanyIacSignature = ({ urlToImage }: Props) => {
  const dispatch = useAppDispatch();

  const { data: imageSrc } = useAppSelector(selectSignatureUrl);

  useEffect(() => {
    const imageUrl = `${API_URL}${urlToImage}`;

    dispatch(getSignatureFromUrl(imageUrl));
  }, [urlToImage]);

  if (!imageSrc) return <SignatureSkeleton />;

  return (
    <div className='flex w-full max-w-lg'>
      <img src={imageSrc} className='h-52' alt='signature' />
    </div>
  );
};

export default CompanyIacSignature;
