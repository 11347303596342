import React from 'react';

import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ROUTES } from 'constants/routes';
import { LinkButton } from 'shared-components';
import Typography from 'shared-components/Typography';

interface Props {
  isShown?: boolean;
  additionalQueryParams?: string;
  className?: string;
}

const AlreadyRegisteredInfoSection = ({ isShown, additionalQueryParams, className }: Props) => {
  const { search } = useLocation();

  const navigateRoute = `${ROUTES.signIn}${search}&${additionalQueryParams}`;

  if (!isShown) return null;

  return (
    <div className={twMerge('flex mb-6', className)}>
      <Typography>You are already registered. Please,</Typography>
      <LinkButton className='ml-1' to={navigateRoute}>
        login
      </LinkButton>
    </div>
  );
};

export default AlreadyRegisteredInfoSection;
