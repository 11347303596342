import React, { useEffect } from 'react';

import { TODOS_SNIPPET_COLUMNS, TODOS_SNIPPET_TITLE } from 'constants/dashboard';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getTodos } from 'modules/todos/action';
import { selectTodosData } from 'modules/todos/selectors';
import { SnippetLayout } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import AdminTodoSnippetItem from './AdminTodoSnippetItem';
import AdminTodosSnippetNoInfo from './AdminTodosSnippetNoInfo';

const REQUEST_PARAMS = {
  page: 1,
  per_page: 5,
};

const AdminTodosSnippet = () => {
  const dispatch = useAppDispatch();

  const { isLoading, list } = useAppSelector(selectTodosData);

  useEffect(() => {
    dispatch(getTodos(REQUEST_PARAMS));
  }, [dispatch]);

  return (
    <SnippetLayout className='col-span-full' title={TODOS_SNIPPET_TITLE}>
      <Table columns={TODOS_SNIPPET_COLUMNS}>
        <>
          {!isLoading && list?.length ? (
            list.map((todo) => <AdminTodoSnippetItem key={todo.id} {...todo} />)
          ) : isLoading ? (
            <TableSkeleton columnsNumber={TODOS_SNIPPET_COLUMNS.length} rowsNumber={3} />
          ) : (
            <AdminTodosSnippetNoInfo />
          )}
        </>
      </Table>
    </SnippetLayout>
  );
};

export default AdminTodosSnippet;
