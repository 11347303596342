export enum InvitedInvestorStatus {
  INVITED = 'invited',
  REGISTERED = 'registered',
}

export interface CompanyInvestor {
  id: number;
  fullName: string;
  firstName?: string;
  lastName?: string;
  email: string;
  registered: boolean;
  invited: boolean;
  status?: InvitedInvestorStatus;
  position?: string;
}

export type AddNewCompanyInvestorFormValues = Pick<CompanyInvestor, 'fullName' | 'email'>;

export interface InviteCompanyInvestorPayload {
  id: string;
  investor: AddNewCompanyInvestorFormValues;
}

export interface InvitedCompanyInvestorPayload {
  id: string;
  investorId: number;
}

export interface ValidateCompanyInvestorsPayload {
  id: string;
  investors: CompanyInvestor[];
}

export interface ValidateAdminCompanyInvestorsPayload {
  investorId: number;
  fullName: string;
  email: string;
}

export interface CompanyInvestorRequestsPayload {
  id: string;
  investorId: number;
}

export interface ValidatedCompanyInvestor {
  isDealParameterNotLive: boolean;
  hasShareExchanges: boolean;
}

export interface CompanyInvestorSendEmailInput {
  content: string;
}

export interface InvitePreviewModalParams {
  companyId: string;
  selectedInvestors: CompanyInvestor[];
  handleResetSelectedInvestors: VoidFunction;
}

export interface InvitePreviewTemplate {
  content: string;
  preview: string;
}

export interface SendInviteInvestorsEmailPayload extends CompanyInvestorSendEmailInput {
  companyId: string;
  investors: CompanyInvestor[];
}

export type SendInviteInvestorEmailPayload = Omit<SendInviteInvestorsEmailPayload, 'investors'> & { id: number };
