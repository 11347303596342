import React, { FC, SVGProps } from 'react';

import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { LinkItem } from 'interfaces';

import { getTitleWithoutMyWord } from '../utils';

interface Props extends LinkItem {
  className?: string;
  iconClassName?: string;
  isTypeEntity?: boolean;
  id?: string;
}

const LinkWithIcon: FC<Props> = ({ title, path, icon, className, iconClassName, target, isTypeEntity, id }) => {
  const modifiedTitle = getTitleWithoutMyWord(title, isTypeEntity);

  const IconComponent = icon as FC<SVGProps<SVGSVGElement>>;
  return (
    <Link
      id={id}
      to={path}
      className={twMerge(
        'flex items-center leading-5 duration-100 text-grey-300 hover:text-white hover:stroke-white hover:fill-white',
        className,
      )}
      target={target}
    >
      <IconComponent className={twMerge('mr-4 min-w-20 min-h-20', iconClassName)} />
      <span>{modifiedTitle}</span>
    </Link>
  );
};

export default LinkWithIcon;
