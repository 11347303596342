import React, { useEffect, useMemo } from 'react';

import { omit } from 'lodash';
import qs from 'query-string';
import { Control, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { CompaniesFilterInputs, Option } from 'interfaces';
import history from 'services/history';
import { SelectFormInput } from 'shared-components';

import { COMPANY_DUE_DILIGENCE_STATUS_OPTIONS, COMPANY_IAC_STATUS_OPTIONS } from '../../constants/companies-iac';

const CompaniesPageFilter = () => {
  const { search } = useLocation();

  const parsedSearchParameters = useMemo(() => qs.parse(search), [search]);

  const { control, watch } = useForm<CompaniesFilterInputs>({
    defaultValues: {
      ddStatus: null,
      iacStatus: null,
    },
  });

  const watchDueDiligenceStatus = watch('ddStatus') as Option;
  const watchIacStatus = watch('iacStatus') as Option;

  const handleReplaceHistorySearch = (searchParams: Record<string, unknown>) => {
    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify(searchParams),
    });
  };

  const resetFilters = (resetKey?: string) => {
    const clearParameters = omit(parsedSearchParameters, resetKey ? [`${resetKey}`] : ['ftype', 'fvalue']);

    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify({ ...clearParameters, page: 1 }),
    });
  };

  useEffect(() => {
    const searchParams: Record<string, string> = {};

    if (watchDueDiligenceStatus) {
      searchParams.ddStatus = watchDueDiligenceStatus?.value;
    }

    if (watchIacStatus) searchParams.iacStatus = watchIacStatus?.value;

    handleReplaceHistorySearch(searchParams);
  }, [watchDueDiligenceStatus, watchIacStatus]);

  return (
    <form className='mb-12 flex gap-4'>
      <SelectFormInput
        name='iacStatus'
        options={COMPANY_IAC_STATUS_OPTIONS}
        onClearCallback={() => resetFilters('iacStatus')}
        label='Filter by Iac Status'
        placeholder='Iac Status'
        control={control as unknown as Control}
        isClearable
      />
      <SelectFormInput
        label='Filter by Due Diligence Status'
        placeholder='Due Diligence Status'
        control={control as unknown as Control}
        name='ddStatus'
        options={COMPANY_DUE_DILIGENCE_STATUS_OPTIONS}
        onClearCallback={() => resetFilters('ddStatus')}
        isClearable
      />
    </form>
  );
};

export default CompaniesPageFilter;
