import React, { RefObject } from 'react';

import SignatureCanvas from 'react-signature-canvas';

import { ButtonVariants } from 'constants/shared/button';
import { updateSignature } from 'modules/signatures/action';
import { selectSignatureIsLoading } from 'modules/signatures/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button } from 'shared-components';

interface Props {
  sigCanvas: RefObject<SignatureCanvas> | null;
}

const SignatureActions = ({ sigCanvas }: Props) => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectSignatureIsLoading);

  const onClear = () => {
    sigCanvas?.current?.clear();
  };

  const onSave = () => {
    const canvas = sigCanvas?.current?.getCanvas();

    if (!canvas) return;

    const formData = new FormData();

    canvas.toBlob(async (blob) => {
      if (blob) {
        const file = new File([blob], 'signature.png', { type: 'image/png' });
        formData.append('signatureFile', file);
        dispatch(updateSignature(formData));
      }
    }, 'image/png');
  };

  return (
    <div className='flex gap-4 flex-col sm:flex-row items-center justify-normal sm:justify-end'>
      <Button variant={ButtonVariants.SECONDARY} onClick={onClear} className='w-full sm:w-[210px]'>
        Clear
      </Button>
      <Button isLoading={isLoading} onClick={onSave} className='w-full sm:w-[210px]'>
        Save Signature
      </Button>
    </div>
  );
};

export default SignatureActions;
