import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';

import { ButtonVariants } from 'constants/shared/button';
import { InvitePreviewModalParams, InvitePreviewTemplate } from 'interfaces';
import { getInvitedCompanyInvestors, sendInviteInvestorsEmail } from 'modules/companies/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, SnippetLayout } from 'shared-components';

import { selectUserType } from '../../modules/current-user/selectors';

const SHARED_TEMPLATE_CLASSNAMES = '[&_a]:text-blue-600 bg-violet-100  h-[300px] overflow-y-auto text-grey-800 p-0';

interface Props extends InvitePreviewModalParams {
  onClose: VoidFunction;
  companyId: string;
  isLoading: boolean;
  handleSetIsLoading: (value: boolean) => void;
  templates: InvitePreviewTemplate;
}

const CompanyInviteInvestorsPreviewEmail: FC<Props> = ({
  companyId,
  onClose,
  templates = {},
  isLoading,
  selectedInvestors,
  handleSetIsLoading,
  handleResetSelectedInvestors,
}) => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(selectUserType);

  const [template, setTemplate] = useState('');

  const { content = '', preview = '' } = templates;

  const handleInvite = () => {
    handleSetIsLoading(true);
    dispatch(sendInviteInvestorsEmail({ companyId, content: template, investors: selectedInvestors, userType }))
      .unwrap()
      .finally(async () => {
        await dispatch(getInvitedCompanyInvestors({ id: companyId, userType }));
        handleResetSelectedInvestors();
        handleSetIsLoading(false);
        onClose();
      });
  };

  const formattedPreview = useMemo(() => {
    if (!template) return '';

    return preview?.replace('{{content}}', template);
  }, [template, preview]);

  useEffect(() => {
    if (template) return;

    setTemplate(content);
  }, [content, template]);
  return (
    <div>
      <SnippetLayout className={SHARED_TEMPLATE_CLASSNAMES}>
        <div
          className='p-4'
          dangerouslySetInnerHTML={{ __html: content }}
          contentEditable
          onInput={(e: ChangeEvent<HTMLDivElement>) => {
            setTemplate(e.target.innerHTML);
          }}
        />
      </SnippetLayout>
      <SnippetLayout className={cn(SHARED_TEMPLATE_CLASSNAMES, 'mt-6 h-max')}>
        <div className='w-fit mt-6 mx-auto p-4' dangerouslySetInnerHTML={{ __html: formattedPreview }} />
      </SnippetLayout>

      <div className='flex justify-between mt-6'>
        <Button
          className='w-fit'
          type='button'
          variant={ButtonVariants.SECONDARY}
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button className='w-fit' isLoading={isLoading} onClick={handleInvite}>
          Send Email
        </Button>
      </div>
    </div>
  );
};

export default CompanyInviteInvestorsPreviewEmail;
