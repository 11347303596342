import React, { useEffect } from 'react';

import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { INVESTOR_PAGE_TABS_IDS, InvestorProfileInfo } from 'constants/investors';
import { ConstraintVariants } from 'constants/shared';
import { TypographyVariants } from 'constants/shared/typography';
import { TabItem } from 'interfaces';
import { getInvestor } from 'modules/investors/action';
import { selectInvestor } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  DashboardLayout,
  InvestorInvoices,
  InvestorDeals,
  InvestorCompaniesInvestments,
  UserInfo,
  UserPageSkeleton,
} from 'page-components';
import { Constraint, Tabs } from 'shared-components';

import Typography from '../shared-components/Typography';

const INVESTOR_PAGE_TABS: TabItem[] = [
  { id: INVESTOR_PAGE_TABS_IDS.INVESTMENTS, name: 'Deals', component: InvestorDeals },
  { id: INVESTOR_PAGE_TABS_IDS.DEALS, name: 'Investments', component: InvestorCompaniesInvestments },
  { id: INVESTOR_PAGE_TABS_IDS.INVOICES, name: 'Invoices', component: InvestorInvoices },
];

const Investor = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { data: investor, isLoading } = useAppSelector(selectInvestor);

  useEffect(() => {
    if (id) {
      dispatch(getInvestor(id));
    }
  }, [dispatch, id]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        {isLoading ? (
          <UserPageSkeleton />
        ) : (
          <div>
            <Typography tag='h2' variant={TypographyVariants.LARGE}>
              Investor Profile: {`${investor?.firstName} ${investor?.lastName}`}
            </Typography>

            <div className='mt-6'>
              <UserInfo title={InvestorProfileInfo.EMAIL_ADDRESS} value={investor?.email} />
              <UserInfo title={InvestorProfileInfo.PHONE_NUMBER} value={investor?.phone} />
              {investor?.created_at && (
                <UserInfo
                  title={InvestorProfileInfo.CREATED_AT}
                  value={format(parseISO(investor?.created_at), DAY_FORMAT_WITH_SLASHES)}
                />
              )}
            </div>
          </div>
        )}
        <Tabs tabs={INVESTOR_PAGE_TABS} />
      </Constraint>
    </DashboardLayout>
  );
};

export default Investor;
