import { SelectOption, FormItem, TableColumns, TodoTypes } from 'interfaces';
import { Input } from 'shared-components';
import SelectFormInput from 'shared-components/SelectFormInput';

import { SHARE_EXCHANGE_STAGES } from './deals';
import { EMAIL_PATTERN } from './global';
import { FieldTypes } from './shared';
import { UserTypes } from './user';

export enum MyInvitesFieldNames {
  EMAIL = 'email',
  USER_TYPE = 'userType',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export const SIGN_OUT_MODAL_TITLE = 'Are you sure you want to Sign out?';

export const ADD_INVESTMENT_MODAL_TITLE = 'Do you have any other Investments in Companies?';

export const ADD_INVESTMENT_MODAL_DESCRIPTION =
  'In order for us to give you the best experience please input any existing investments that you would like to keep track of.';

export const MY_INVESTMENTS_SNIPPET_TITLE = 'Overview of all your investments';

export const TODOS_SNIPPET_TITLE = 'Pending actions';

export const INVESTORS_DONE_SHARE_EXCHANGES_SNIPPET_TITLE = 'Investors with more than one Share Exchange done';

export const FUND_DOCUMENTS_SNIPPET_TITLE = 'Fund Documents';

export const MY_INVITES_SNIPPET_TITLE = 'My invites';

export const MY_INVITES_SNIPPET_DESCRIPTION =
  'Invite people you know to register with planD, including other investors, founders or company directors.';

export const REGISTERED_USERS_TOOLTIP_MESSAGE = 'Investors who have not yet opted into the share exchange';

export const PENDING_INVESTORS_TOOLTIP_MESSAGE = 'Investors requested the share exchange for the company under review';

export const INVESTORS_TOOLTIP_MESSAGE = 'Investors with share exchange in progress';

export const MY_INVESTMENTS_SNIPPET_COLUMNS: TableColumns[] = [
  { id: 'companyName', title: 'Company Name' },
  { id: 'classOfShares', title: 'Class of Shares' },
  { id: 'investmentValue', title: 'Investment Value' },
  { id: 'action', title: '', width: 'w-1/6' },
];

export const TODOS_SNIPPET_COLUMNS: TableColumns[] = [
  { id: 'action_type', title: 'Action' },
  { id: 'actions', title: '', width: 'w-1/6' },
];

export const TODO_TYPES_TEST: {
  [key in TodoTypes]: string;
} = {
  [TodoTypes.DUE_DILIGENCE]: 'Due Diligence',
  [TodoTypes.SHARE_EXCHANGE_REQUEST]: 'Share Exchange Request',
  [TodoTypes.IAC]: 'IAC',
};

export const SHARE_EXCHANGE_SNIPPET_TITLE = 'Share Exchange';

export const SHARE_EXCHANGE_MULTIPLE_SNIPPET_TITLE = 'Share Exchanges';

export const DASHBOARD_MY_INVITES_MODAL_TITLE = 'Fill the form';

export const DASHBOARD_INVESTMENTS_METRICS_DESCRIPTION = 'Overview of all your investments';

export const DASHBOARD_PIE_CHART_COLORS = [
  '#558217',
  '#FF5963',
  '#596FFF',
  '#A4B0FF',
  '#59C8FF',
  '#AFE06C',
  '#FFC859',
  '#00C2B5',
  '#90D331',
  '#E0672A',
];

export const GET_DASHBOARD_MY_INVITES_FORM_FIELDS = (hideUserType?: boolean): FormItem[] => [
  {
    name: MyInvitesFieldNames.FIRST_NAME,
    label: 'First Name',
    placeholder: 'First Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: 'Required information missing please input First name to continue' },
    className: 'min-height-input mb-6',
  },
  {
    name: MyInvitesFieldNames.LAST_NAME,
    label: 'Last Name',
    placeholder: 'Last Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: 'Required information missing please input Last name to continue' },
    className: 'min-height-input mb-6',
  },
  {
    name: MyInvitesFieldNames.EMAIL,
    label: 'Email address',
    placeholder: 'Email Address',
    type: FieldTypes.TEXT,
    component: Input,
    validation: {
      required: 'Required information missing please input Email address to continue',
      pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
    },
    className: 'min-height-input mb-6',
  },
  ...(!hideUserType
    ? [
        {
          type: FieldTypes.SELECT,
          name: MyInvitesFieldNames.USER_TYPE,
          label: 'User type',
          placeholder: 'User Type',
          component: SelectFormInput,
          options: [
            { label: 'Investor', value: 'investor' },
            { label: 'Founder', value: 'founder' },
          ],
          validation: { required: 'Required information missing please select User Type to continue' },
        },
      ]
    : []),
];

export const GRAPHS_RANGES_DROPDOWN: SelectOption[] = [
  { label: 'Week', value: 'w' },
  { label: 'Month', value: 'm' },
  { label: '3 Months', value: '3m' },
  { label: '6 Months', value: '6m' },
  { label: '9 Months', value: '9m' },
  { label: 'Year', value: 'y' },
];

export const DASHBOARD_CONTAINER_CLASSNAMES: {
  [key in UserTypes]: string;
} = {
  [UserTypes.ADMIN]: 'grid-cols-2 2lg:grid-cols-[minmax(400px,1fr)_1fr_400px] 2xl:grid-cols-3',
  [UserTypes.INVESTOR]: 'grid-cols-2 xl:grid-cols-[60%_1fr] 2xl:grid-cols-[minmax(68%,1fr)_minmax(0,1fr)]',
  [UserTypes.FOUNDER]: 'grid-cols-1',
  [UserTypes.FM_USER]: '',
};

export const DASHBOARD_DEALS_SNIPPET_STAGES_LABELS = {
  [SHARE_EXCHANGE_STAGES.APPLICATION_FORM]: 'AF',
  [SHARE_EXCHANGE_STAGES.APPROPRIATENESS_KYC]: 'IAQ',
  [SHARE_EXCHANGE_STAGES.APPROVAL]: 'Approval',
  [SHARE_EXCHANGE_STAGES.COMPLETED]: 'Completed',
  [SHARE_EXCHANGE_STAGES.INTRODUCER_AGREEMENT]: 'IA',
  [SHARE_EXCHANGE_STAGES.INVOICE]: 'Invoice',
  [SHARE_EXCHANGE_STAGES.READY_TO_COMPLETE]: 'PC',
  [SHARE_EXCHANGE_STAGES.SHARE_EXCHANGE_AGREEMENT]: 'SEA',
  [SHARE_EXCHANGE_STAGES.STOCK_TRANSFER_FORM]: 'STF',
};
