import React, { useEffect } from 'react';

import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as HandSvg } from 'assets/svg/hand.svg';
import { LocalStorageKeys } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { TypographyVariants } from 'constants/shared/typography';
import { SIGN_IN_FLOWS } from 'constants/sign-in';
import { UserTypes } from 'constants/user';
import useModal from 'hooks/use-modal/useModal';
import { selectUser, selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { DashboardLayout, DashboardSnippets, WelcomeInvestorModal } from 'page-components';
import Typography from 'shared-components/Typography';
import { getInvestmentsStartShareExchange } from 'utils';
import { getParsedWelcomeModalUsersIds } from 'utils/investments';

const Dashboard = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const { firstName, lastName, userType, id, entityCompany } = useAppSelector(selectUser) || {};
  const { isTypeEntity } = useAppSelector(selectUserEntityData);

  const fullUserName = `${firstName} ${lastName}`;

  const {
    isOpen: isOpenInvestorWelcomeModal,
    onClose: onCloseInvestorWelcomeModal,
    onOpen: onOpenInvestorWelcomeModal,
  } = useModal();

  useEffect(() => {
    if (userType !== UserTypes.INVESTOR || pathname?.includes(ROUTES.signUp)) {
      return;
    }

    const formattedSearch = state?.search?.replace('?', '');

    const { company_id: companyId, flow } = qs.parse(formattedSearch);

    if (flow === SIGN_IN_FLOWS.CREATE_SHARE_EXCHANGE) {
      navigate(getInvestmentsStartShareExchange(companyId as string, false));
      return;
    }
  }, [navigate, state?.search, userType]);

  useEffect(() => {
    if (userType !== UserTypes.INVESTOR) {
      return;
    }

    if (localStorage.getItem(LocalStorageKeys.HIDE_INVESTOR_WELCOME_MODAL)) {
      return;
    }

    const shouldBeHidden = getParsedWelcomeModalUsersIds().some((userId: number) => userId === id);

    if (shouldBeHidden) return;

    onOpenInvestorWelcomeModal();
  }, [userType, onOpenInvestorWelcomeModal, id]);

  return (
    <>
      <WelcomeInvestorModal
        firstName={isTypeEntity ? entityCompany?.companyName : firstName}
        isOpen={isOpenInvestorWelcomeModal}
        onClose={onCloseInvestorWelcomeModal}
      />

      <DashboardLayout>
        <Typography className='ml-[30px] mt-6 mx-[40px] flex' tag='p' variant={TypographyVariants.LARGE}>
          Hello {isTypeEntity ? entityCompany?.companyName : fullUserName} <HandSvg className='ml-2 mt-5' />
        </Typography>
        <DashboardSnippets />
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
