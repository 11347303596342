import { useState, useEffect } from 'react';

import { omit } from 'lodash';
import { UseFormSetValue, Path, PathValue, UseFormReset, FieldValues } from 'react-hook-form';

import { COMPANY_DATA_FORM_ADDITIONAL_DEFAULT_VALUES, CompanyDataFieldNames } from 'constants/companies';
import { warningNotify } from 'helpers';
import { Option, CompanyData, WithIsCreated, CompanyDetailsInput } from 'interfaces';
import { getCompanyProfileByNumber } from 'modules/companies/action';
import { useAppDispatch } from 'modules/store';

type HandleChangeCompanyValueType = (option: WithIsCreated<Option> | null) => void;

const useCompanySelect = <T extends FieldValues>(
  watch: (names?: string | string[]) => unknown,
  setValue: UseFormSetValue<T>,
  reset: UseFormReset<T>,
  keepResetDefaultValues: boolean,
  company: CompanyData | null,
  setAsAvailable: VoidFunction,
): [HandleChangeCompanyValueType, HandleChangeCompanyValueType, boolean | undefined, boolean | undefined] => {
  const companyData = omit(company, ['companyStatus', 'companyType', 'registeredOffice']);

  const [isMounted, setIsMounted] = useState(false);
  const previousFormValues = watch() as CompanyDetailsInput;

  const dispatch = useAppDispatch();

  const { label: companyNumberLabel, isCreated: isCompanyNumberCreated } =
    (watch(CompanyDataFieldNames.COMPANY_NUMBER) as WithIsCreated<Option>) || {};

  const { value: companyNameValue, isCreated: isCompanyNameCreated } =
    (watch(CompanyDataFieldNames.COMPANY_NAME) as WithIsCreated<Option>) || {};

  const handleChangeSelectValues = ({
    option,
    key,
    additionalIsCreated,
  }: {
    option: WithIsCreated<Option> | null;
    key: 'companyNumber' | 'companyName';
    additionalIsCreated?: boolean;
  }) => {
    const { label, value, isCreated } = option || {};

    if (isCreated && !additionalIsCreated)
      warningNotify('The company was not found, further creation of the company will be carried out manually.');

    if (isCreated) {
      reset({
        ...(companyNameValue !== value && !additionalIsCreated
          ? COMPANY_DATA_FORM_ADDITIONAL_DEFAULT_VALUES
          : previousFormValues),
        [key]: option,
      } as unknown as T);
      setAsAvailable();

      return;
    }
    setValue(key as Path<T>, (option === null ? null : { label, value }) as PathValue<T, Path<T>>);

    if (option !== null) {
      setValue(
        (key === 'companyName' ? 'companyNumber' : 'companyName') as Path<T>,
        { label: value, value: label } as PathValue<T, Path<T>>,
      );
      return;
    }
  };

  const handleChangeCompanyName = (option: WithIsCreated<Option> | null) => {
    handleChangeSelectValues({ option, key: 'companyName', additionalIsCreated: isCompanyNumberCreated });
  };

  const handleChangeCompanyNumber = (option: WithIsCreated<Option> | null) => {
    handleChangeSelectValues({ option, key: 'companyNumber', additionalIsCreated: isCompanyNameCreated });
  };

  useEffect(() => {
    if (
      isMounted &&
      companyNumberLabel &&
      companyData?.companyNumber !== companyNumberLabel &&
      !isNaN(Number(companyNumberLabel))
    ) {
      dispatch(getCompanyProfileByNumber(companyNumberLabel));
    }
    // eslint-disable-next-line
  }, [companyNumberLabel, isCompanyNumberCreated, dispatch]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!Object.values(companyData || {})?.length || isCompanyNameCreated || isCompanyNumberCreated) {
      return;
    }

    reset(
      {
        ...companyData,
        companyName: {
          value: companyData?.companyNumber || '',
          label: companyData?.companyName || '',
        },
        companyNumber: {
          value: companyData?.companyName || '',
          label: companyData?.companyNumber || '',
        },
      } as unknown as T,
      { keepDefaultValues: keepResetDefaultValues },
    );
    // eslint-disable-next-line
  }, [company, isCompanyNameCreated, isCompanyNumberCreated, keepResetDefaultValues, reset]);

  return [handleChangeCompanyName, handleChangeCompanyNumber, isCompanyNameCreated, isCompanyNumberCreated];
};

export default useCompanySelect;
