import React, { FC, useState } from 'react';

import cn from 'classnames';
import { format, parseISO } from 'date-fns';

import { ReactComponent as SortingArrow } from 'assets/svg/arrow-drop-down.svg';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { NOTIFICATIONS_TABLE_COLUMNS } from 'constants/notifications';
import { NotificationStatus, UserNotification } from 'interfaces';
import { getUser } from 'modules/current-user/action';
import { updateNotification } from 'modules/notifications/action';
import { useAppDispatch } from 'modules/store';
import { TableData, TableRow } from 'shared-components/table';

type Props = {
  notification: UserNotification;
};

const NotificationsTableRow: FC<Props> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const [isOpened, setIsOpened] = useState(false);

  const onRowClick = () => {
    setIsOpened((prevState) => !prevState);

    if (notification.status === NotificationStatus.UNREAD) {
      dispatch(updateNotification(notification.id))
        .unwrap()
        .then(() => dispatch(getUser()));
    }
  };

  return (
    <>
      <TableRow
        className={cn('cursor-pointer', { 'bg-grey-100': notification.status === NotificationStatus.UNREAD })}
        onClick={onRowClick}
      >
        <TableData className='pl-1'>{notification.companyName}</TableData>
        <TableData>{format(parseISO(notification.createdAt), DAY_FORMAT_WITH_SLASHES)}</TableData>
        <TableData>
          <SortingArrow
            className={cn('w-6 h-6 transition-all delay-300 duration-500 [&_path]:fill-grey-500', {
              'rotate-180': isOpened,
            })}
          />
        </TableData>
      </TableRow>
      <TableRow
        className={cn('transition-all duration-500 min-h-0 border-b-0 transition-all  opacity-0', {
          'opacity-100': isOpened,
        })}
      >
        <TableData
          className={cn('transition-all duration-500 py-0', { 'py-4': isOpened })}
          colSpan={NOTIFICATIONS_TABLE_COLUMNS.length}
        >
          <div
            className={cn('grid grid-rows-[0fr] [&>*]:overflow-hidden transition-all duration-500', {
              'grid-rows-[1fr]': isOpened,
            })}
          >
            <div className={cn('col-span-full transition-all duration-500 px-2 ml-5 ')}>{notification.message}</div>
          </div>
        </TableData>
      </TableRow>
    </>
  );
};

export default NotificationsTableRow;
