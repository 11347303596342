import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminSignaturesApis, signaturesUrlApis } from 'apis/signatures.apis';

export const SIGNATURES_SLICE_NAME = 'signatures';

export const updateSignature = createAsyncThunk(
  `${SIGNATURES_SLICE_NAME}/updateSignature`,
  async (payload: FormData) => {
    const response = await adminSignaturesApis.updateSignature(payload);

    return response.data.data;
  },
);

export const getSignatureFromUrl = createAsyncThunk(
  `${SIGNATURES_SLICE_NAME}/getSignatureFromUrl`,
  async (payload: string) => {
    const response = await signaturesUrlApis.getSignatureFromUrl(payload);
    const arrayBuffer = response.data;

    const uint8Array = new Uint8Array(arrayBuffer);

    const binaryString = uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
    const base64String = window.btoa(binaryString);

    return `data:${response.headers['content-type']};base64,${base64String}`;
  },
);
