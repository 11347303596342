import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { INVESTORS_DONE_SHARE_EXCHANGES_SNIPPET_TITLE } from 'constants/dashboard';
import { INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS } from 'constants/investors';
import { ROUTES } from 'constants/routes';
import { getInvestorsDoneShareExchanges } from 'modules/investors/action';
import { selectInvestorsData } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import InvestorsTableRow from '../../../investors/InvestorsTableRow';
import InvestorDoneShareExchangesSnippetNoInfo from './InvestorDoneShareExchangesSnippetNoInfo';

const InvestorsDoneShareExchangesSnippet = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { list, isLoading } = useAppSelector(selectInvestorsData);

  const onRowClick = (id: number) => () => navigate(`${ROUTES.admin}${ROUTES.investor}`.replace(':id', id.toString()));

  useEffect(() => {
    dispatch(getInvestorsDoneShareExchanges());
  }, [dispatch]);

  return (
    <SnippetLayout title={INVESTORS_DONE_SHARE_EXCHANGES_SNIPPET_TITLE} className='col-span-2 self-start'>
      <Table columns={INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS}>
        <>
          {!isLoading && list?.length ? (
            list.map((investor) => (
              <InvestorsTableRow key={investor.id} investor={investor} onRowClick={onRowClick} withDoneShareExchanges />
            ))
          ) : isLoading ? (
            <TableSkeleton columnsNumber={INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS.length} rowsNumber={3} />
          ) : (
            <InvestorDoneShareExchangesSnippetNoInfo />
          )}
        </>
      </Table>
    </SnippetLayout>
  );
};

export default InvestorsDoneShareExchangesSnippet;
