import React, { FC } from 'react';

import cn from 'classnames';

import { ButtonDropdownActions, Investor, InvestorActionsParamsType } from 'interfaces';
import { ButtonDropdown } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

type Props = {
  investor: Investor;
  getActions?: (data: InvestorActionsParamsType) => ButtonDropdownActions[];
  withDoneShareExchanges?: boolean;
  onRowClick?: (id: number) => () => void;
};

const InvestorsTableRow: FC<Props> = ({ investor, getActions, withDoneShareExchanges, onRowClick }) => (
  <TableRow>
    {!withDoneShareExchanges && getActions && (
      <TableData className='pl-1'>
        <ButtonDropdown className='w-40' actions={getActions({ id: investor.id })} />
      </TableData>
    )}
    <TableData className={cn({ 'cursor-pointer': onRowClick })} onClick={onRowClick?.(investor.id)}>
      {investor.firstName}
    </TableData>
    <TableData className={cn({ 'cursor-pointer': onRowClick })} onClick={onRowClick?.(investor.id)}>
      {investor.lastName}
    </TableData>
    {!withDoneShareExchanges && (
      <>
        <TableData>{investor.email}</TableData>
        <TableData>{investor.phone}</TableData>
      </>
    )}
    {withDoneShareExchanges && <TableData className='text-center'>{investor?.noDoneSEs}</TableData>}
  </TableRow>
);

export default InvestorsTableRow;
