import React, { ChangeEvent, FC, useMemo, useState } from 'react';

import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { Path } from 'react-hook-form';

import {
  BUSINESS_REVIEW_FORM_FIELDS,
  BUSINESS_REVIEW_RADIO_BUTTONS_WITH_NOTES_FIELDS,
  BusinessReviewRadioFieldNames,
  InvestmentProposalBlockNames,
  SIGNATURE_RADIO,
} from 'constants/company-iac';
import { DAY_FORMAT_WITH_HOURS } from 'constants/dateFormats';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { UserTypes } from 'constants/user';
import useModal from 'hooks/use-modal/useModal';
import { UseCompanyIacOverviewReturnValues } from 'hooks/useCompanyIacBusinessReview';
import { CompanyIacBusinessReviewFormValues } from 'interfaces';
import { cancelCompanyIacSignature, signCompanyIac } from 'modules/company-iac/action';
import { selectCompanyIac, selectCompanyIacId } from 'modules/company-iac/selectors';
import { selectUser, selectUserType } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal, ConditionalRender, FormContent, FormLabel, LinkButton } from 'shared-components';
import { checkIsAdmin, getSigningCompanyIacModalTitle } from 'utils';

import CompanyIacAddCustomFields from '../company-iac-add-custom-fields/CompanyIacAddCustomFields';
import CompanyIacCustomFieldsForm from '../company-iac-custom-fields-form/CompanyIacCustomFieldsForm';
import CompanyIacBlock from '../CompanyIacBlock';
import CompanyIacRadioInputWithNotes from '../CompanyIacRadioInputWithNotes';
import CompanyIacSignature from '../CompanyIacSignature';

type Props = Omit<UseCompanyIacOverviewReturnValues, 'trigger' | 'reset'> & {
  hideAdminActions: boolean;
};

const CompanyIacBusinessReview: FC<Props> = ({
  isLoading,
  isEditing,
  control,
  register,
  errors,
  watch,
  unregister,
  setValue,
  isHideActionButtons,
  handleSaveEditionCustomField,
  handleChangeNotesTextFields,
  handleSaveRadioInputValue,
  handleSaveBusinessReviewFields,
  customFieldsData,
  hideAdminActions,
}) => {
  const dispatch = useAppDispatch();
  const companyIac = useAppSelector(selectCompanyIac);
  const iacId = useAppSelector(selectCompanyIacId);

  const user = useAppSelector(selectUser);
  const userType = useAppSelector(selectUserType);
  const [isSignatureLoading, setIsSignatureLoading] = useState(false);

  const { onOpen: handleOpenSignatureModal, onClose: handleCloseSignatureModal, ...signatureModalState } = useModal();

  const signatureFieldKey = BusinessReviewRadioFieldNames.SIGNATURE as Path<CompanyIacBusinessReviewFormValues>;

  const { signedAt = '' } = companyIac?.signature || {};

  const isSignatureSigning = signatureModalState?.modalProps?.value === 'true';

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const { registerCustomFields, onDeleteCustomField, deletedFields } = customFieldsData;

  const onSignatureConfirm = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as Path<CompanyIacBusinessReviewFormValues>;

    const currentValue = event.target?.value;

    setValue(name, currentValue === 'true' ? 'false' : 'true');
    handleOpenSignatureModal({ value: currentValue });
  };

  const handleCompanyIacSigning = () => {
    setIsSignatureLoading(true);

    const dispatchFunction = isSignatureSigning ? signCompanyIac : cancelCompanyIacSignature;

    dispatch(dispatchFunction(iacId))
      .unwrap()
      .then(() => {
        setValue(signatureFieldKey, String(isSignatureSigning));
        handleCloseSignatureModal();
      })
      .finally(() => setIsSignatureLoading(false));
  };

  return (
    <>
      <AlertModal
        onSubmit={handleCompanyIacSigning}
        isLoading={isSignatureLoading}
        title={getSigningCompanyIacModalTitle(companyIac?.companyName, isSignatureSigning)}
        submitButtonText='Confirm'
        hideIcon
        onClose={handleCloseSignatureModal}
        {...signatureModalState}
      />

      <CompanyIacBlock title='Business Review' isHideActionButtons={isHideActionButtons} isLoading={isLoading}>
        <form>
          <FormContent
            isDisabled={isEditing}
            fields={BUSINESS_REVIEW_FORM_FIELDS(isAdmin)}
            register={register}
            errors={errors}
            control={control}
            className={cn('mb-5', { 'disabled-light-grey': isEditing })}
            onBlur={handleSaveBusinessReviewFields}
          />

          {BUSINESS_REVIEW_RADIO_BUTTONS_WITH_NOTES_FIELDS(isAdmin).map((field, index) => (
            <CompanyIacRadioInputWithNotes
              setValue={setValue}
              unregister={unregister}
              key={index}
              isDisabled={isEditing}
              register={register}
              errors={errors}
              watch={watch}
              radioInputProps={field.radioInput}
              textAreaProps={field.textAreaInput}
              onChangeRadioInput={handleSaveRadioInputValue}
              onChangeNotes={handleChangeNotesTextFields}
            />
          ))}

          <ConditionalRender conditional={userType === UserTypes.ADMIN}>
            {user?.signature ? (
              <CompanyIacRadioInputWithNotes
                isDisabled={isEditing || !companyIac?.id}
                setValue={setValue}
                unregister={unregister}
                register={register}
                errors={errors}
                watch={watch}
                radioInputProps={SIGNATURE_RADIO}
                onChangeRadioInput={onSignatureConfirm}
                customComponent={
                  <div>
                    {signedAt ? (
                      <>
                        <FormLabel>
                          Signed by {user?.firstName} {user?.lastName} on{' '}
                          {format(parseISO(signedAt), DAY_FORMAT_WITH_HOURS)}
                        </FormLabel>
                        <CompanyIacSignature urlToImage={user?.signature?.imageLink || ''} />
                      </>
                    ) : null}
                  </div>
                }
              />
            ) : (
              <LinkButton variant={ButtonVariants.PRIMARY} to={`/admin${ROUTES.businessSettings}?tab=signature`}>
                Create Signature
              </LinkButton>
            )}
          </ConditionalRender>
        </form>

        <CompanyIacCustomFieldsForm
          errors={customFieldsData.customFieldsErrors}
          disabled={isEditing}
          deletedFields={deletedFields}
          onDeleteCustomField={onDeleteCustomField}
          register={registerCustomFields}
          blockName={InvestmentProposalBlockNames.BUSINESS_REVIEW}
          onBlur={handleSaveEditionCustomField}
        />

        <CompanyIacAddCustomFields
          blockName={InvestmentProposalBlockNames.BUSINESS_REVIEW}
          disabled={isEditing}
          hideAddNewFieldButton={hideAdminActions}
        />
      </CompanyIacBlock>
    </>
  );
};

export default CompanyIacBusinessReview;
