import { FormItem, TableColumns } from 'interfaces';
import { Input } from 'shared-components';

import { FieldTypes } from './shared';

export const DELETE_COMPANY_MODAL_TITLE = 'Are you sure you want to remove this company?';

export const DELETE_COMPANY_WITH_LIVE_DEAL_MODAL_TITLE =
  'This company has active deal, are you sure want to remove this company?';

export enum CompanyDataFieldNames {
  ADDRESS_1 = 'addressLine1',
  ADDRESS_2 = 'addressLine2',
  COMPANY_NUMBER = 'companyNumber',
  COMPANY_NAME = 'companyName',
  AMOUNT_INVESTED = 'amountInvested',
  COUNTY = 'county',
  CITY = 'city',
  COUNTRY = 'country',
  POSTCODE = 'postcode',
  WEBSITE = 'website',
}

export const CURRENT_COMPANY_DEFAULT_STATE = {
  data: null,
  holders: [],
  officers: [],
  isLoading: false,
};

export const ADMIN_COMPANIES_PAGE_TITLE = 'Companies';

export const COMPANIES_ADD_MODAL_TITLE = 'Add a new company';

export const COMPANIES_ADD_MODAL_DESCRIPTION = 'Search by either Company No. or Company name';

export const COMPANIES_ADD_MODAL_SUBMIT_TEXT = 'Add Company ';

export const COMPANIES_INVITE_MODAL_TITLE = 'Invitation Email';

export const COMPANIES_INVITE_MODAL_DESCRIPTION =
  'Please review the email below and feel free to use the provided / suggested wording or tailor the text to suit. The text in ‘{{ }}’ brackets will be completed automatically';

export const COMPANY_DATA_FIELDS: FormItem[] = [
  {
    name: CompanyDataFieldNames.ADDRESS_1,
    label: 'Address Line 1',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    className: 'col-span-full xs:col-span-2 mb-6',
    validation: { required: 'Required information missing please input Address 1 to continue' },
  },
  {
    name: CompanyDataFieldNames.ADDRESS_2,
    label: 'Address Line 2',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'col-span-full xs:col-span-2 xs:col-span-2 xs:pl-2.5 mb-6',
  },
  {
    name: CompanyDataFieldNames.COUNTY,
    label: 'County',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    className: 'col-span-full xs:col-span-2 mb-6',
    validation: { required: 'Required information missing please input Country 1 to continue' },
  },
  {
    name: CompanyDataFieldNames.CITY,
    label: 'City',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    className: 'col-span-full xs:col-span-2 xs:pl-2.5 mb-6',
    validation: { required: 'Required information missing please input City to continue' },
  },
  {
    name: CompanyDataFieldNames.COUNTRY,
    label: 'Country',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    className: 'col-span-full xs:col-span-1 mb-6',
    validation: { required: 'Required information missing please input Country to continue' },
  },
  {
    name: CompanyDataFieldNames.POSTCODE,
    label: 'Postcode',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    className: 'col-span-2 xs:col-span-1 mb-6',
    validation: { required: 'Required information missing please input Postcode to continue' },
  },
  {
    name: CompanyDataFieldNames.WEBSITE,
    label: 'Website',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'col-span-2 gap-5 xs:pl-2.5 mb-6',
  },
];

export const COMPANIES_STATE_DEFAULT_COMPANY_DATA = {
  data: null,
  isLoading: false,
  called: false,
};

export const COMPANY_DATA_EXCLUDED_KEYS = ['dateIncorporated', 'directors', 'shareholders', 'sicCodes'];

export const ADMIN_COMPANIES_TABLE_COLUMNS: TableColumns[] = [
  { id: 'actions', title: 'Actions', width: 'w-44' },
  { id: 'companyName', title: 'Company Name', sortable: true },
  { id: 'companyNumber', title: 'Company Number', sortable: true },
  { id: 'iac', title: 'IAC' },
  { id: 'diligence', title: 'Due Diligence' },
];

export const COMPANY_DATA_FORM_ADDITIONAL_DEFAULT_VALUES = {
  companyName: null,
  companyNumber: null,
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  county: '',
  postcode: '',
  website: '',
};
