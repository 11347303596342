import axios, { AxiosInstance } from 'axios';

import { LocalStorageKeys } from 'constants/global';
import { getAccessToken, resetUserStorage } from 'utils';

const { REACT_APP_API_URL } = process.env;

export const API_URL = window?._env_?.REACT_APP_API_URL || REACT_APP_API_URL;

function createHttpClient(endPoint = ''): AxiosInstance {
  const instance = axios.create({
    baseURL: `${API_URL}${endPoint}`,
    timeout: 0,
  });

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem(LocalStorageKeys.AUTH_ACCESS_TOKEN) || getAccessToken();
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      };
    }
    return config;
  });

  instance.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if (err.response.status === 401 && !window?.location?.pathname?.includes('sign-up')) {
      resetUserStorage();
    }
    return Promise.reject(err);
  });

  return instance;
}

export const httpApiClient = createHttpClient('/api/v1');
