import React from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { ENTITY_TAB_DESCRIPTION, ENTITY_TAB_TITLE, getEntitySettingsDefaultValues } from 'constants/settings';
import { CompanyEntityInput } from 'interfaces';
import { selectUser, selectUserEntityData } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { TitleWithFormActions } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

import { updateEntity } from '../../modules/current-user/action';
import EntityCompanyFields from './EntityCompanyFields';
import EntityInvestors from './EntityInvestors';

const EntitySettings = () => {
  const dispatch = useAppDispatch();
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const user = useAppSelector(selectUser);

  const {
    control,
    watch,
    setValue,
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CompanyEntityInput>({
    defaultValues: getEntitySettingsDefaultValues(user?.entityCompany),
  });

  const onSubmit: SubmitHandler<CompanyEntityInput> = (data) => {
    dispatch(updateEntity(data));
  };

  const onCancel = () => reset();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-7'>
        <TitleWithFormActions
          title={ENTITY_TAB_TITLE}
          description={getTitleWithReplacedYourWord(ENTITY_TAB_DESCRIPTION, entityName, isTypeEntity)}
          onCancel={onCancel}
        />
        <div className='grid grid-cols-4 gap-x-7'>
          <EntityCompanyFields
            control={control}
            watch={watch}
            setValue={setValue}
            reset={reset}
            register={register}
            errors={errors}
            isStaticCompany
          />
        </div>
      </form>
      <EntityInvestors />
    </>
  );
};

export default EntitySettings;
