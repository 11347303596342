import React, { PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Control, Controller, FieldValues, Path, PathValue, Validate } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone';
import { twMerge } from 'tailwind-merge';

import { INCORRECT_PHONE_NUMBER_MESSAGE } from 'constants/global';
import { FormItem } from 'interfaces';
import { ErrorMessage, FormLabel } from 'shared-components/index';

// eslint-disable-next-line
import 'react-international-phone/style.css';
import { TypographyVariants } from '../constants/shared/typography';
import Typography from './Typography';

const ERROR_CLASSNAME =
  '!border-red-600 !border-2 !text-red-500 [&.react-international-phone-country-selector-button]:!border-r';

interface Props<T extends FieldValues>
  extends Pick<
    FormItem,
    'validation' | 'className' | 'description' | 'name' | 'placeholder' | 'disabled' | 'required'
  > {
  control: Control<T>;
  label?: string;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const validatePhoneNumber = (phone: string): string | undefined => {
  if (!phone) return undefined;
  try {
    const isValidNumber = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));

    if (!isValidNumber) return INCORRECT_PHONE_NUMBER_MESSAGE;

    return undefined;
  } catch {
    return INCORRECT_PHONE_NUMBER_MESSAGE;
  }
};

const PhoneField = <T extends FieldValues>({
  control,
  className,
  name,
  label,
  description,
  validation,
  placeholder,
  required,
  ...props
}: PropsWithChildren<Props<T>>): ReactElement => {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      rules={{ ...validation, validate: validatePhoneNumber as Validate<PathValue<T, Path<T>>> }}
      render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
        return (
          <div className={twMerge('mb-6', className)}>
            <FormLabel>
              {label}
              {required && '*'}
            </FormLabel>
            <PhoneInput
              {...props}
              {...fieldProps}
              value={(value as string) || ''}
              placeholder={placeholder}
              countrySelectorStyleProps={{
                buttonClassName: twMerge(
                  cn('!py-3.75 !px-4 !w-[56px] !h-auto !border-grey-200 !rounded-l-lg', {
                    [ERROR_CLASSNAME]: error?.message,
                    '!bg-grey-100 !border-grey-200': props?.disabled,
                  }),
                ),
              }}
              inputClassName={twMerge(
                cn('!py-3.75 !px-4 leading-6 !h-auto !w-full !border-grey-200 !rounded-r-lg', {
                  [ERROR_CLASSNAME]: error?.message,
                  '!bg-grey-100 !border-grey-200': props?.disabled,
                }),
              )}
              defaultCountry='gb'
            />

            {description && !error?.message && (
              <Typography className='mt-1' variant={TypographyVariants.BODY_SMALL}>
                {description}
              </Typography>
            )}
            <ErrorMessage error={error?.message} />
          </div>
        );
      }}
    />
  );
};

export default PhoneField;
