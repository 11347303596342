import { useEffect } from 'react';

import { matchRoutes, useLocation } from 'react-router-dom';

import { CAMEL_CASE_PATTERN } from 'constants/global';
import { ROUTES } from 'constants/routes';

export const useCurrentPath = () => {
  const formattedRoutes = Object.values(ROUTES).map((route) => ({ path: route }));

  const { pathname, ...location } = useLocation();
  const matchedRoute = matchRoutes(formattedRoutes, {
    pathname: pathname.includes('admin') ? pathname.replace('/admin', '') : pathname,
    ...location,
  });

  useEffect(() => {
    if (!matchedRoute) return;

    const [{ route }] = matchedRoute;
    const currentPath = Object.keys(ROUTES).find((routeKey) => ROUTES[routeKey].includes(route.path));

    if (currentPath) {
      const titleFromPath = currentPath.replace(CAMEL_CASE_PATTERN, '$1$4 $2$3$5');
      const formattedTitle = titleFromPath.replace(titleFromPath.charAt(0), titleFromPath.charAt(0).toUpperCase());

      document.title = `PlanD - ${formattedTitle}`;
    }
  }, [pathname, matchedRoute]);
};
