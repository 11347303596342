import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_METRICS_STATE } from 'constants/shared';
import {
  CommonMetrics,
  Investor,
  InvestorActivityLogsResponse,
  InvestorCompaniesInvestmentsResponse,
  InvestorInvoicesResponse,
  InvestorPieChartMetrics,
  InvestorsDoneShareExchanges,
  InvestorShareExchangesResponse,
  InvestorsResponse,
  InvestorsState,
} from 'interfaces';
import { AuditLog } from 'interfaces/audit-logs.interfaces';

import {
  getEntityActivityLog,
  getEntityActivityLogs,
  getInvestor,
  getInvestorCompaniesInvestments,
  getInvestorsDoneShareExchanges,
  getInvestorInvoices,
  getInvestors,
  getInvestorShareExchanges,
  getInvestorsMetrics,
  getInvestorsPieChartMetrics,
} from './action';

export function getInvestorsReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestors.pending, (state) => {
    state.investorsList.isLoading = true;
  });

  builder.addCase(getInvestors.fulfilled, (state, action: PayloadAction<InvestorsResponse>) => {
    state.investorsList.isLoading = false;
    state.investorsList.list = action.payload.data;
    state.investorsList.totalPages = action.payload.meta.last_page;
    state.investorsList.total = action.payload.meta.total;
  });

  builder.addCase(getInvestors.rejected, (state) => {
    state.investorsList.isLoading = false;
  });
}

export function getInvestorReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestor.pending, (state) => {
    state.investor.isLoading = true;
  });

  builder.addCase(getInvestor.fulfilled, (state, action: PayloadAction<Investor>) => {
    state.investor.isLoading = false;
    state.investor.data = action.payload;
  });

  builder.addCase(getInvestor.rejected, (state) => {
    state.investor.isLoading = false;
  });
}

export function getInvestorCompaniesInvestmentsReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestorCompaniesInvestments.pending, (state) => {
    state.investorCompaniesInvestments.isLoading = true;
  });

  builder.addCase(
    getInvestorCompaniesInvestments.fulfilled,
    (state, action: PayloadAction<InvestorCompaniesInvestmentsResponse>) => {
      state.investorCompaniesInvestments.isLoading = false;
      state.investorCompaniesInvestments.list = action.payload.data;
      state.investorCompaniesInvestments.totalPages = action.payload.meta.last_page;
      state.investorCompaniesInvestments.total = action.payload.meta.total;
    },
  );

  builder.addCase(getInvestorCompaniesInvestments.rejected, (state) => {
    state.investorCompaniesInvestments.isLoading = false;
  });
}

export function getInvestorShareExchangesReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestorShareExchanges.pending, (state) => {
    state.investorShareExchanges.isLoading = true;
  });

  builder.addCase(
    getInvestorShareExchanges.fulfilled,
    (state, action: PayloadAction<InvestorShareExchangesResponse>) => {
      state.investorShareExchanges.isLoading = false;
      state.investorShareExchanges.list = action.payload.data;
      state.investorShareExchanges.totalPages = action.payload.meta.last_page;
      state.investorShareExchanges.total = action.payload.meta.total;
    },
  );

  builder.addCase(getInvestorShareExchanges.rejected, (state) => {
    state.investorShareExchanges.isLoading = false;
  });
}

export function getInvestorInvoicesReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestorInvoices.pending, (state) => {
    state.investorInvoices.isLoading = true;
  });

  builder.addCase(getInvestorInvoices.fulfilled, (state, action: PayloadAction<InvestorInvoicesResponse>) => {
    state.investorInvoices.isLoading = false;
    state.investorInvoices.list = action.payload.data;
    state.investorInvoices.totalPages = action.payload.meta.last_page;
    state.investorInvoices.total = action.payload.meta.total;
  });

  builder.addCase(getInvestorInvoices.rejected, (state) => {
    state.investorInvoices.isLoading = false;
  });
}

export function getInvestorsMetricsReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestorsMetrics.pending, (state) => {
    state.investorsMetrics.isLoading = true;
  });

  builder.addCase(getInvestorsMetrics.fulfilled, (state, action: PayloadAction<CommonMetrics>) => {
    state.investorsMetrics = { ...action.payload, isLoading: false };
  });

  builder.addCase(getInvestorsMetrics.rejected, (state) => {
    state.investorsMetrics = { ...DEFAULT_METRICS_STATE, isLoading: false };
  });
}

export function getInvestorsWithDoneShareExchangesReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestorsDoneShareExchanges.pending, (state) => {
    state.investorsList.isLoading = true;
  });

  builder.addCase(
    getInvestorsDoneShareExchanges.fulfilled,
    (state, action: PayloadAction<InvestorsDoneShareExchanges>) => {
      state.investorsList.isLoading = false;
      state.investorsList.list = action.payload.withMoreThenOneDoneSEs;
    },
  );

  builder.addCase(getInvestorsDoneShareExchanges.rejected, (state) => {
    state.investorsList.isLoading = false;
  });
}

export function getInvestorsPieChartMetricsReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestorsPieChartMetrics.pending, (state) => {
    state.investorsPieMetrics.isLoading = true;
  });

  builder.addCase(getInvestorsPieChartMetrics.fulfilled, (state, action: PayloadAction<InvestorPieChartMetrics>) => {
    state.investorsPieMetrics = { chartData: { ...action.payload }, isLoading: false };
  });

  builder.addCase(getInvestorsPieChartMetrics.rejected, (state) => {
    state.investorsPieMetrics = { isLoading: false, chartData: null };
  });
}

export function getEntityAuditLogsReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getEntityActivityLogs.pending, (state) => {
    state.activityLogs.isLoading = true;
  });

  builder.addCase(getEntityActivityLogs.fulfilled, (state, action: PayloadAction<InvestorActivityLogsResponse>) => {
    state.activityLogs.isLoading = false;
    state.activityLogs.list = action.payload.data;
    state.activityLogs.totalPages = action.payload.meta.last_page;
    state.activityLogs.total = action.payload.meta.total;
  });

  builder.addCase(getEntityActivityLogs.rejected, (state) => {
    state.activityLogs.list = null;
    state.activityLogs.isLoading = false;
  });
}

export function getEntityAuditLogByIdReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getEntityActivityLog.pending, (state) => {
    state.activityLog.isLoading = true;
  });

  builder.addCase(getEntityActivityLog.fulfilled, (state, action: PayloadAction<AuditLog>) => {
    state.activityLog.log = action.payload;
    state.activityLog.isLoading = false;
  });

  builder.addCase(getEntityActivityLog.rejected, (state) => {
    state.activityLog.isLoading = false;
  });
}
