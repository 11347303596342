import React, { FC } from 'react';

import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ButtonVariants } from 'constants/shared/button';
import { InvitedUserStatus } from 'constants/user';
import { DeletePendingInviteUserFunctionType, InvitedUser } from 'interfaces';
import { Button } from 'shared-components';

interface Props extends InvitedUser {
  handleDeleteInvite: DeletePendingInviteUserFunctionType;
}

const MyInvitesItem: FC<Props> = ({ id, email, status, handleDeleteInvite }) => {
  const onClickDeleteInvite = () => handleDeleteInvite({ id, email });

  return (
    <li className='flex justify-between items-center gap-1 mt-4 text-violet-500'>
      <p>{email}</p>
      <div className='flex'>
        <p>{status}</p>
        {status === InvitedUserStatus.PENDING ? (
          <Button className='w-5 p-0 ml-6' variant={ButtonVariants.TRANSPARENT} onClick={onClickDeleteInvite}>
            <DeleteSvg />
          </Button>
        ) : (
          <div className='w-5 ml-6' />
        )}
      </div>
    </li>
  );
};

export default MyInvitesItem;
